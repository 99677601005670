(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    init: function init() {
        var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

        this.cfgs = $.extend(true, {
            selector: '.masonry-cards',
            type: 'subject',
            more: '.more',
            loading: '.loading',
            nomore: '.nomore',
            nodata: '.nodata',
            isauthor: 0,
            page: 1,
            perpage: 1,
            masonry: {
                itemSelector: '.card'
            },
            grid: null,
            callback: null,
            fromUser: null
        }, options);

        // console.log(this.cfgs.fromUser);

        this.state = {};
        this.datas = {};
        this.state.type = this.cfgs.type;
        this.state.page = this.cfgs.page;
        this.state.isauthor = this.cfgs.isauthor;
        this.state.url = this.cfgs.url || $(this.cfgs.more).find('a').attr('href');
        this.state.perpage = this.cfgs.perpage;
        this.state.fromUser = this.cfgs.fromUser;
        this.setup();

        // console.log(this.state.fromUser);
    },
    setup: function setup() {
        var _this = this;

        $(this.cfgs.selector).masonry(this.cfgs.masonry);

        $(this.cfgs.more).find('a').on('click', function (e) {
            _this.loadMore();
            e.preventDefault();
        });

        if (typeof this.cfgs.callback === 'function') {
            this.cfgs.callback(this);
        }
    },
    loadMore: function loadMore() {
        var _this2 = this;

        if (this.state.loading) return;

        this.showLoading(true);

        var jqxhr = $.getJSON(this.state.url, $.extend(this.datas, {
            page: this.state.page
        }), function (data) {
            if (data.total > 0) {
                // console.log(this.datas);
                $(_this2.cfgs.nodata).hide();
                _this2.addItems(data.data);
                _this2.state.page += 1;
                _this2.state.perpage = data.per_page;

                if (data.current_page < data.last_page) {
                    $(_this2.cfgs.more).show();
                } else {
                    _this2.showNoMore();
                }
            } else {
                $(_this2.cfgs.nodata).show();
            }

            if ($('.masonry-search .tt-text').length > 0 && $('.masonry-search .tt-text').val().length >= 2) {
                $('.masonry-search .btn-search').removeAttr('disabled');
                $('.masonry-search .btn-search').css('background-color', "#9B9B9B");
            }
        });

        jqxhr.complete(function () {
            _this2.showLoading(false);
        });
    },
    addItems: function addItems(data) {
        var tpl = '';
        var $tpl = '';
        var cardClass = 'card col-xs-12 col-sm-6 col-md-4 col-lg-3';
        var companyClass = 'card col-xs-12 col-sm-12 col-md-12 col-lg-12';
        var cards = $(this.cfgs.selector);
        var grid = this.cfgs.grid;
        var isauthor = this.state.isauthor;
        var fromUser = this.state.fromUser;

        switch (grid) {
            case 940:
                cardClass = 'card col-xs-12 col-sm-6 col-md-4';
                break;
        }

        if (this.state.type === 'subject') {
            data.forEach(function (val) {
                var viewtpl = '<span></span>';
                var viewJobTpl = '';
                var post_permission = "";
                var hasjobs = "";

                switch (val.post_permission) {
                    case 'resume':
                        post_permission = '<span class="tag">应聘</span>';
                        break;
                    case 'private':
                        post_permission = '<span class="tag">私密</span>';
                        break;
                    default:
                        break;
                }

                if (val.pv > 0) {
                    /*if(val.icon.length > 0) {
                        viewtpl = `<span class="score">&cent;${val.pv}</span>`;
                    } else {
                        viewtpl = `<span class="score pull-right">&cent;${val.pv}</span>`;
                    }*/
                    viewtpl = '<span class="price pull-right">&cent;' + val.pv + '</span>';
                }

                if (val.author.jobs && val.author.jobs.length > 0) {
                    hasjobs = "has-jobs";
                    viewJobTpl += '<div class="meta text-left list-jobs clearfix"> <p>本司热招：</p>';
                    val.author.jobs.forEach(function (job, index) {
                        viewJobTpl += '<p> <a href="' + job.url + '"> ' + job.job_city + ' : ' + job.title + ' </a> </p>';
                    });
                    viewJobTpl += '</div>';
                }
                // console.log(val.cover);
                tpl += '\n                    <div class="' + cardClass + '">\n                        <div class="wrap ' + hasjobs + '">\n                            <div class="title">\n                                <a href="' + val.url + '" target="_blank">' + val.title + '</a>\n                                ' + post_permission + '\n                            </div>\n                            <div class="meta">\n                                <a class="subject-author" href="/users/' + val.author.encodeId + '" target="_blank">' + val.author.name + '</a>\n                                <span class="pull-right">' + val.simple_published_at + '</span>\n                                <div class="clearfix"></div>\n                            </div>\n                            <div class="meta text-center clearfix">\n                                <span class="pull-left">VIEW:' + val.view_count + '</span>\n                                \n                                ' + viewtpl + '\n                                ' + val.icon + '\n                                \n                            </div>\n                            ' + val.cover + '\n\n                            ' + viewJobTpl + '\n                        </div>\n                    </div>\n                ';
            });
        } else if (this.state.type === 'user') {
            data.forEach(function (val) {
                var postTpl = '';
                var privateMessageTpl = '';
                val.posts.forEach(function (post, index) {
                    var estimateTpl = '';
                    var postImgUrl = '';
                    var imageUrl = post.cover_image.replace("-indexCover", "").replace("-postContent", "");
                    if (imageUrl == "") {
                        postImgUrl = "https://www.mxdia.com/assets/img/mxdia_icon.png";
                    } else {
                        postImgUrl = imageUrl.indexOf("https://static.mxdia.com") != -1 ? imageUrl : 'https://static.mxdia.com' + imageUrl;
                    }

                    if (post.estimate > 0) {
                        estimateTpl = '<span class="post_estimate">￥' + post.estimate + '万元</span>';
                    }

                    if (index > 1) {
                        postTpl += '\n                            <div class="col-xs-6 col-sm-4 col-md-2 visible-md visible-lg user_list_post_cover">\n                                <object>\n                                    <a href="' + post.url + '" style="background-image: url(\'' + postImgUrl + '-list360thumb\'); display: block; height: 115px; width: 100%; background-position: top center; background-size: cover;"></a>\n                                    ' + estimateTpl + '\n                                </object>\n                            </div>\n                        ';
                    } else {
                        postTpl += '\n                            <div class="col-xs-6 col-sm-4 col-md-2 user_list_post_cover">\n                                <object>\n                                    <a href="' + post.url + '" style="background-image: url(\'' + postImgUrl + '-list360thumb\'); display: block; height: 115px; width: 100%; background-position: top center; background-size: cover;"></a>\n                                    ' + estimateTpl + '\n                                </object>\n                            </div>\n                        ';
                    }
                });

                if (fromUser != null) {
                    privateMessageTpl = '\n                        <object>\n                            <a onclick="sendMessage(event);" href="javascript:;" class="info type send_message" data-href="/notifications/' + fromUser + '/send/' + val.encodeId + '/private" data-method="POST">私信TA</a>\n                        </object>\n                    ';
                }

                tpl += '\n                    <div class="' + companyClass + ' company user_list">\n                        <a class="wrap" href="' + val.href + '" target="_blank">\n                            <div class="col-xs-12 col-sm-4 col-md-4">\n                                <div class="tt-user">\n                                    <img src="' + val.avatar_url + '" alt="">\n                                    <span class="info">\n                                        ' + val.name + '\n                                    </span>\n                                    ' + privateMessageTpl + ('\n                                    <div class="meta" style="clear: both;">\n                                    ' + val.tags + '\n                                    </div>\n                                </div>\n                                <div class="detail user-detail clearfix">\n                                    <span>文章: ' + val.post_count + '</span>\n                                    <span>Like:' + val.liked_count + '</span>\n                                    <span> ' + val.icon + ' ' + val.simple_created_at + '</span>\n                                </div>\n                            </div>\n                            ') + postTpl + '\n                            <div class="clearfix"></div>\n                        </a>\n                    </div>\n                ';
            });
        } else if (this.state.type === 'company') {
            data.forEach(function (val) {
                var postTpl = '';
                var privateMessageTpl = '';
                val.posts.forEach(function (post, index) {
                    var estimateTpl = '';
                    var postImgUrl = '';
                    var imageUrl = post.cover_image.replace("-indexCover", "").replace("-postContent", "");
                    if (imageUrl == "") {
                        postImgUrl = "https://www.mxdia.com/assets/img/mxdia_icon.png";
                    } else {
                        postImgUrl = imageUrl.indexOf("https://static.mxdia.com") != -1 ? imageUrl : 'https://static.mxdia.com' + imageUrl;
                    }

                    if (post.estimate > 0) {
                        estimateTpl = '<span class="post_estimate test">￥' + post.estimate + '万元</span>';
                    }

                    if (index > 1) {
                        postTpl += '\n                            <div class="col-xs-6 col-sm-4 col-md-2 visible-md visible-lg user_list_post_cover">\n                                <object>\n                                    <a href="' + post.url + '" style="background-image: url(\'' + postImgUrl + '-list360thumb\'); display: block; height: 115px; width: 100%; background-position: top center; background-size: cover;"></a>\n                                    ' + estimateTpl + '\n                                </object>\n                            </div>\n                        ';
                    } else {
                        postTpl += '\n                            <div class="col-xs-6 col-sm-4 col-md-2 user_list_post_cover">\n                                <object>\n                                    <a href="' + post.url + '" style="background-image: url(\'' + postImgUrl + '-list360thumb\'); display: block; height: 115px; width: 100%; background-position: top center; background-size: cover;"></a>\n                                    ' + estimateTpl + '\n                                </object>\n                            </div>\n                        ';
                    }
                });

                if (fromUser != null) {
                    privateMessageTpl = '\n                        <object>\n                            <a onclick="sendMessage(event);" href="javascript:;" class="info type send_message" data-href="/notifications/' + fromUser + '/send/' + val.encodeId + '/private" data-method="POST">私信TA</a>\n                        </object>\n                    ';
                }

                tpl += '\n                    <div class="' + companyClass + ' company user_list">\n                        <a class="wrap" href="' + val.href + '" target="_blank">\n                            <div class="col-xs-12 col-sm-4 col-md-4">\n                                <div class="tt-user">\n                                    <img src="' + val.avatar_url + '" alt="">\n                                    <span class="info">\n                                        ' + val.name + '\n                                    </span>\n                                    ' + privateMessageTpl + ('\n                                    <div class="meta" style="clear: both;">\n                                    ' + val.tags + '\n                                    </div>\n                                </div>\n                                <div class="detail user-detail clearfix">\n                                    <span>文章: ' + val.post_count + '</span>\n                                    <span>Like:' + val.liked_count + '</span>\n                                    <span class="pull-right">' + val.icon + ' ' + val.simple_created_at + '</span>\n                                </div>\n                            </div>\n                            ') + postTpl + '\n                            <div class="clearfix"></div>\n                        </a>\n                    </div>\n                ';
            });
        } else if (this.state.type === 'job') {
            data.forEach(function (val) {
                tpl += '\n                    <div class="' + cardClass + ' job">\n                        <div class="wrap">\n                            <div class="title"><a href="' + val.url + '" target="_blank">' + val.job_city + '：' + val.title + '</a></div>\n                            <img class="img-placeholder">\n                            <div class="meta">\n                                <span>月薪 ' + val.salary + ' </span>\n                            </div>\n                            <div class="meta">\n                                <a href="/users/' + val.user.encodeId + '" target="_blank">' + val.user.name + '</a>\n                                <span class="pull-right">' + val.simple_published_at + '</span>\n                            </div>\n                            <div class="meta text-center clearfix">\n                                <span class="pull-left">VIEW:' + val.view_count + '</span>\n                                ' + val.icon + '\n                            </div>\n                        </div>\n                    </div>\n                ';
            });
        } else if (this.state.type === 'company_job') {
            data.forEach(function (val) {
                tpl += '\n                    <div class="' + cardClass + ' job">\n                        <div class="wrap">\n                            <div class="title"><a href="' + val.url + '" target="_blank">' + val.job_city + '：' + val.title + '</a></div>\n                            <img class="img-placeholder">\n                            <div class="meta">\n                                <span>月薪 ' + val.salary + ' </span>\n                            </div>\n                            <div class="meta">\n                                <a href="/users/' + val.user.encodeId + '" target="_blank">' + val.user.name + '</a>\n                                <span class="pull-right">' + val.simple_updated_at + '</span>\n                            </div>\n                            <div class="meta text-center clearfix">\n                                <span class="pull-left">VIEW:' + val.view_count + '</span>\n                                ' + val.icon + '\n                            </div>\n                        </div>\n                    </div>\n                ';
            });
        }

        $tpl = $(tpl);
        $tpl.hide();

        cards.append($tpl);
        cards.masonry('appended', $tpl);
        $tpl.imagesLoaded().progress(function (imgLoad, image) {
            var $item = $(image.img).parents('.card');
            $item.fadeIn('fast');
            cards.masonry('layout');
        });
    },
    showLoading: function showLoading(show) {
        this.state.loading = show;

        if (show) {
            $(this.cfgs.loading).show();
        } else {
            $(this.cfgs.loading).hide();
        }
    },
    showNoMore: function showNoMore() {
        var _this3 = this;

        this.state.finished = true;
        $(this.cfgs.more).hide();
        $(this.cfgs.nomore).show();
        setTimeout(function () {
            $(_this3.cfgs.nomore).hide();
        }, 2000);
    },
    enableMasonry: function enableMasonry() {
        $(this.cfgs.selector).masonry(this.cfgs.masonry);
    },
    disableMasonry: function disableMasonry() {
        this.state.page = 1;
        this.showLoading(false);
        $(this.cfgs.more).hide();
        $(this.cfgs.nomore).hide();
        $(this.cfgs.selector).html('').masonry('destroy');
    },
    setState: function setState(prop, val) {
        this.state[prop] = val;
    },
    setData: function setData(prop, val) {
        this.datas[prop] = val;
    }
};

},{}],2:[function(require,module,exports){
'use strict';

var _masonry = require('../modules/masonry');

var _masonry2 = _interopRequireDefault(_masonry);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_masonry2.default.init({
    type: selectType == 'user' ? 'user' : 'subject',
    fromUser: fromUserId != "" ? fromUserId : null,
    callback: function callback(self) {
        var search = '';
        var tags = '';
        var tagsNew = '';
        self.setData('channel', selectedCategoryId ? selectedCategoryId : selectedChannelId);
        self.setData('category', selectedCategoryId);
        self.setData('filter', filter);
        self.setData('search_users', '');
        self.setData('tags', '');
        self.loadMore();

        $('.masonry-search .btn-search').on('click', function () {
            self.disableMasonry();
            search = $(this).prev('.tt-text').val();
            self.enableMasonry();
            $(this).attr('disabled', true);
            $(this).css('background-color', "#CCC");
            loadMore();
        });

        $('.masonry-search .tt-text').on('change', function () {
            search = this.value;
        });

        $('.masonry-search .tt-text').keyup(function (e) {
            if (this.value.length >= 2 && e.keyCode == 13 && !self.state.loading) {
                self.disableMasonry();
                loadMore();
                self.enableMasonry();
            } else if (this.value.length >= 2) {
                $('.masonry-search .btn-search').removeAttr('disabled');
                $('.masonry-search .btn-search').css('background-color', "#9B9B9B");
            } else {
                $('.masonry-search .btn-search').attr('disabled', true);
                $('.masonry-search .btn-search').css('background-color', "#CCC");
            }
            if (this.value.length == 0) {
                self.disableMasonry();
                loadMore();
                self.enableMasonry();
            }
        });

        var currentChannel = selectedChannelId;
        $('.masonry-category select').on('change', function () {
            var category = $(this).data('category');
            var value = this.value;

            self.disableMasonry();

            self.setData('channel', currentCategoryId);
            self.setData('category', '');
            self.setData('filter', '');
            switch (category) {
                case 'type':
                    var jumpUrl = baseUrl;
                    if (value == 'user') {
                        jumpUrl += '?type=user';
                    } else if (value == 'company') {
                        jumpUrl += 'companies';
                    }
                    window.location.href = jumpUrl;
                    break;
                case 'category':
                    var jumpUrl = baseUrl;
                    if (value) {
                        jumpUrl += 'categories/' + (value ? value : currentChannel);
                        if (filter) {
                            jumpUrl += '?filter=' + filter;
                        }
                    }
                    window.location.href = jumpUrl;
                    break;
                case 'filter':
                    var jumpUrl = currentCategoryId ? baseUrl + 'categories/' + currentCategoryId + '?filter=' + value : baseUrl + '?filter=' + value + (selectType == 'user' ? '&type=user' : '');
                    window.location.href = jumpUrl;
                    break;
            }
        });

        $('.search-tags li').on('click', function () {
            var thisTagId = $(this).attr('id');
            var thisTagName = $(this).attr('val');

            console.log(thisTagId + '=>' + thisTagName);

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).children('a').css('background', 'none');
                $('.show-tag-list li[id="' + thisTagId + '"]').remove();
            } else {
                $(this).children('a').css('background', '#fff');
                $(this).addClass('active');

                $('.show-tag-list').append('<li id="' + thisTagId + '" style="margin:3px;"><a class="label label-success" href="javascript:;">' + thisTagName + '<button type="button" class="close" data-dismiss="alert" aria-label="Close" style="font-size: 12px; opacity: 1;color: #d43f3a; margin-left: 4px;"><span aria-hidden="true">×</span></button></a></li>');
            }

            tags = '';
            tagsNew = '';

            $('#tags_container li').each(function (index, e) {
                if ($(e).hasClass("active")) {
                    tags = $(e).attr('id');
                    tagsNew = tagsNew + tags + ",";
                }
            });

            if (tagsNew.length > 0) {
                $('.btn-search-with-tags').attr('disabled', false);
                $('.btn-search-with-tags').show();
            } else {
                self.disableMasonry();
                loadMore();
                self.enableMasonry();
                $('.btn-search-with-tags').attr('disabled', true);
                $('.btn-search-with-tags').hide();
            }
        });

        $('.btn-search-with-tags').on('click', function () {
            self.disableMasonry();
            loadMore();
            self.enableMasonry();

            if (tagsNew.length <= 0) {
                $('.btn-search-with-tags').attr('disabled', true);
                $('.btn-search-with-tags').hide();
            }
        });

        $(document).on('click', '.show-tag-list li', function () {

            var thisTagId = $(this).attr('id');
            var thisTag = $('ul li[id="' + thisTagId + '"]');
            tags = '';
            tagsNew = '';

            if (thisTag.hasClass('active')) {
                thisTag.removeClass('active');
                thisTag.children('a').css('background', 'none');
            }
            $(this).remove();

            $('#tags_container li').each(function (index, e) {
                if ($(e).hasClass("active")) {
                    tags = $(e).attr('id');
                    tagsNew = tagsNew + tags + ",";
                }
            });

            if (tagsNew.length > 0) {
                $('.btn-search-with-tags').attr('disabled', false);
                $('.btn-search-with-tags').show();
            } else {
                self.disableMasonry();
                loadMore();
                self.enableMasonry();
                $('.btn-search-with-tags').attr('disabled', true);
                $('.btn-search-with-tags').hide();
            }
        });

        function loadMore() {
            self.setData('tags', tagsNew);
            self.setData('search_users', search);
            self.loadMore();

            $(window).trigger('reposition');
        }
    }
});

},{"../modules/masonry":1}]},{},[2]);
